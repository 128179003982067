import { createApp } from 'vue'
import axios from "axios"
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import HighchartsVue from 'highcharts-vue';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const API_URL = "https://api.reinvent.witmo.eu"

axios.defaults.baseURL = API_URL
axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
axios.defaults.headers.put['Access-Control-Allow-Origin'] = '*';

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
createApp(App).use(pinia).use(HighchartsVue).use(router).mount('#app')
