<template>
  <div class="container">
    <div class="card h-100">
      <div class="card-header">
      <b>Devices</b>
      </div>
      <div class="card-body">

        <div class="mb-3 row">
          <div class="mb-3 row">
            <label class="col-sm-2 col-form-label">Select device: </label>
            <div class="col-sm-10">
              <select class="form-select" v-model="measurementRequested"
                :disabled="databaseStore.measurementAggregator.length === 0"
                aria-label="Default select example" @change="getChannels($event)">
                <option v-for="device in databaseStore.devices" 
                    :value="device.device_id"
                  >
                    {{ device.reference }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
  <div v-if="channelsFromDevice.length !== 0">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Reference</th>
          <th scope="col">Measurement</th>
          <th scope="col">Gateway</th>
          <th scope="col">Loop</th>
          <th scope="col">Available</th>
          <th scope="col">Last Access</th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr>
          <td>{{ channelsFromDevice.data.reference }}</td>
          <td>{{ channelsFromDevice.data.measurement }}</td>
          <td>{{ channelsFromDevice.data.gateway }}</td>
          <td>{{ channelsFromDevice.data.timer_loop }} segs</td>
          <td>{{ channelsFromDevice.data.available }}</td>
          <td>{{ channelsFromDevice.data.last_access }}</td>
        </tr>
      </tbody>
    </table>  
    <div>
    <table class="table table-sm table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Item</th>
          <th scope="col">Unit</th>
          <th scope="col">Channel</th>
          <th scope="col">Id</th>
          <th scope="col"></th>
        </tr>
      </thead>

      <tbody class="table-group-divider">
        <tr v-for="(channel, index) in channelsFromDevice.data.channels" :key="index">
          <th scope="row">
            <input
            class="form-check-input"
            type="checkbox"
            v-model="channelSelected"
            :value="channel"
          >
          </th>
          <td>{{ channel.item }}</td>
          <td>{{ channel.unit }}</td>
          <td>{{ channel.channel }}</td>
          <td class="table-active">{{ channel.channel_id }}
          </td>
          <td>
            <button type="button" class="btn btn-outline-secondary" @click="createChart(channel)" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-graph-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M0 0h1v15h15v1H0zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07"/>
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
</div>
</div>

<!-- Chart Modal -->
<div class="modal fade" id="chartModal" tabindex="-1" aria-labelledby="chartModal_label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="chartModal_label">Historical Data</h1>
        <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
      </div>
      <div class="modal-body">
      <highcharts :options="chartOptions"></highcharts>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </div>
  </div>
</div>

<!-- Error Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModal_label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="errorModal_label">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
          </svg>
        </h5>
        <button type="button" class="btn-close" aria-label="Close" @click="closeErrorModal"></button>
      </div>
      <div class="modal-body">
        {{ errorMessage }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" @click="closeErrorModal">Close</button>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
  import { useDatabases } from '@/store/DBStore';
  import { storeToRefs } from 'pinia';
  import { onMounted, reactive, ref, watch } from "vue";

  const errorMessage = ref('');

  const databaseStore = useDatabases()
  const { 
    databaseSelected, 
    deviceSelected,
    channelSelected,
    channelsFromDevice,
    spinner,
    chartOptions,
    measurementSelected,
    measurementListFromSelectedChannel
  } = storeToRefs(databaseStore);
  const {
    fetchGetChannelValues,
    fetchGetChannels
  } = databaseStore

  const getChannels = async (event) => {
    try {
      await fetchGetChannels(event.target.value)
    } catch (error) {
      event.preventDefault();
    } 
  }

  const state = reactive({
      chartModal: null,
      errorModal: null
  })
  watch(channelSelected, () => {
    console.log(channelsFromDevice.value.data.measurement)
    if (
      (!measurementListFromSelectedChannel.value.includes(channelsFromDevice.value.data.measurement)) && 
      (channelsFromDevice.value.data.measurement != null)) {
      measurementListFromSelectedChannel.value.push(channelsFromDevice.value.data.measurement)
    }
    if (channelSelected.value.length == 0) {
      measurementListFromSelectedChannel.value = []
    }
  })
  const createChart = async(channel) => {
    spinner.value = true
    const response = await fetchGetChannelValues(
      channel, channelsFromDevice.value.data.measurement)
    if (response.error == false) {
      spinner.value = false
      state.chartModal.show()
    } else {
      errorMessage.value = response.message
      spinner.value = false
      state.errorModal.show()
    }
  }

  onMounted(async () => {
    if (databaseSelected.value != null) {
      await databaseStore.fetchDevices(); 
    }
    state.chartModal = new bootstrap.Modal('#chartModal', {})
    state.errorModal = new bootstrap.Modal('#errorModal', {})
  })

  const closeModal = () => {
    state.chartModal.hide()
    databaseStore.chartOptions.series = []
  }

  const closeErrorModal = () => {
    state.errorModal.hide()
  }
</script>