<template>
  <div class="container">
    <div class="card h-100">
      <div class="card-header">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-database-fill-check" viewBox="0 0 16 16">
        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1"/>
        <path d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12 12 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.55 4.55 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-1.3-1.905"/>
      </svg>
      </div>
      <div class="card-body">

          <table class="table">
          <tr class="form-check" v-for="(database, index) in databaseStore.getDatabases">
            <td>
              <input
                class="form-check-input"
                type="radio"
                v-model="databaseSelected"
                :value="database"
              >
            </td>
            <td>
              <label class="form-check-label" for="flexRadioDefault2">
              {{ database }}
              </label>
            </td>
          </tr>
          </table>
      </div>
      <div class="card-footer">
        <button
          class="btn btn-success me-md-2"
          type="button"
          @click="fetchMeasurementAgregator()"
          :disabled="databaseSelected == null"
        >
        Salect Database
        </button>
        <button
          class="btn btn-danger"
          type="button"
          @click="resetDatabase()"
        >
        Reset
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useDatabases } from '@/store/DBStore';
  import { storeToRefs } from 'pinia';
  import { onMounted } from "vue";

  const databaseStore = useDatabases();
  const { databaseSelected } = storeToRefs(databaseStore);
  const { fetchMeasurementAgregator } = databaseStore
  onMounted(async () => {
    await databaseStore.fetchDatabases();
  })

  const resetDatabase = async() => {
    databaseStore.$reset()  
    await databaseStore.fetchDatabases();
  }
</script>
