import axios from "axios"
import { router } from '../router/index.js'
import { defineStore } from 'pinia'



export const useUser = defineStore('user', {
  state: () => ({
      token: null,
      isAuthenticated: false,
      user: {
          email: null,
          is_active:false,
          is_superuser:false,
          full_name:null,
          id: null
      }
  }),
  actions: {
    async fetchUser(email, password) {
      try {
        const data = await axios.post(
          "/api/v1/login/access-token", {
            username:email, password:password
          }, {
            headers: {'Content-Type': 'multipart/form-data'},
          }
        )
        this.token = data.data.access_token
        this.isAuthenticated = true
        router.push('/');
      } catch (error) {
        alert("Wrong email or password");
        this.isAuthenticated = false
      }

    },
    async tryToken() {
      try {
        const data = await axios.post(
          "/api/v1/login/test-token", {},{
            headers: {
              'Authorization': `Bearer ${this.token}`
            },
          }
        )
        this.user = data.data
        this.isAuthenticated = true
        return true
      } catch (error) {
        this.isAuthenticated = false
        return false
      }
    },
    logout() {
      this.token = null
      this.isAuthenticated = false
      router.push('/login');
    }
  },
  persist: true
})
