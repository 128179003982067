<template>
  <nav v-if="isAuthenticated" class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
  <a class="navbar-brand" href="/">
      <img src="reinventicon.png" alt="Logo" width="30"class="d-inline-block align-text-top">
    </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" 
        data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item"><router-link class="nav-link" to="/">Home</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/devices">Devices</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/data_table">Table Values</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/graphql">GraphQL</router-link></li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  User
                </a>
                <ul class="dropdown-menu dropdown-menu-dark">
                  <li class="nav-item"><router-link class="nav-link d-flex" to="/profile">Profile</router-link></li>
                  <li class="nav-item"><router-link class="nav-link d-flex" @click="logout" to="">Logout</router-link></li>
                </ul>
              </li>

          </ul>
      </div>
  </div>
</nav>
  <router-view/>
</template>
<script setup>
import { useUser } from './store/UserStore';
import { useDatabases } from '@/store/DBStore';
import { storeToRefs } from 'pinia';

const useUserStore = useUser();
const useDatabase = useDatabases();

const {
  isAuthenticated
} = storeToRefs(useUserStore);
const {
  databaseSelected,
  measurementSelected,
  fileSelected,
  measurementAggregator,
  filesFromMeasurement
} = storeToRefs(useDatabase);
const logout = () => {
  useUserStore.logout();
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>