<template>
  <div class="container">
    <div class="card h-100">
      <div class="card-header">
        <b>Channels Selected</b>
      </div>
      <div class="card-body">
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label">Start Date:</label>
          <div class="col-sm-8">
            <vue-date-picker v-model="datetimeFrom" :format="format" :disabled="channelSelected.length === 0"></vue-date-picker>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label">End Date:</label>
          <div class="col-sm-8">
            <vue-date-picker v-model="datetimeEnd" :format="format" :disabled="channelSelected.length === 0"></vue-date-picker>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label">Interval:</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" v-model="interval" :disabled="channelSelected.length === 0"
              id="validationTooltip01" required>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-4 col-form-label">Measurements:</label>
          <div class="col-sm-8">
              <select class="form-select" v-model="measurementRequested"
                :disabled="measurementListFromSelectedChannel.length === 0"
                aria-label="Default select example">
                <option v-for="measurement in measurementListFromSelectedChannel">
                    {{ measurement }}
                </option>
              </select>
            </div>
          </div>
      </div>
      <div v-if="channelSelected.length !== 0">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Item</th>
              <th scope="col">Unit</th>
              <th scope="col">Channel</th>
              <th scope="col">Id</th>
            </tr>
          </thead>

          <tbody class="table-group-divider">
            <tr v-for="(channel, index) in channelSelected" :key="index">
              <td>
                {{ channel.item }}
              </td>
              <td>
                {{ channel.unit }}
              </td>
              <td>
                {{ channel.channel }}
              </td>
              <td class="table-active">
                {{ channel.channel_id }}
              </td>
            </tr>
          </tbody>
        </table>

    </div>
    <div class="card-footer">
      <button class="btn btn-success me-md-2" type="button" :disabled="channelSelected.length === 0"
        @click="downloadChannelsList($event)">
          Download CSV
      </button>
      <button class="btn btn-danger" type="button"
        :disabled="channelSelected.length === 0"
        @click="deleteChannelList($event)">
        Reset Channels
      </button>
    </div>
    </div>
  </div>
<!-- Error Modal -->
<div class="modal fade" id="errorSelectionModal" tabindex="-1" aria-labelledby="errorModal_label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="errorModal_label">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
          </svg>
        </h5>
        <button type="button" class="btn-close" aria-label="Close" @click="closeErrorModal"></button>
      </div>
      <div class="modal-body">
        {{ errorMessage }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" @click="closeErrorModal">Close</button>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
  import VueDatePicker from '@vuepic/vue-datepicker';
  import { useDatabases } from '@/store/DBStore';
  import { storeToRefs } from 'pinia';
  import { onMounted, reactive, ref } from "vue";
  import '@vuepic/vue-datepicker/dist/main.css'


  const format = ($this) => {
    const day = $this.getDate().toString().padStart(2, '0');
    const _month = $this.getMonth()+1;
    const month = _month.toString().padStart(2, '0');
    const year = $this.getFullYear();
    const hours = $this.getHours().toString().padStart(2, '0');
    const minutes = $this.getMinutes().toString().padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}`
  }

  const interval = ref(15);
  const errorMessage = ref('');

  const databaseStore = useDatabases();
  const {
    channelSelected,
    measurementSelected,
    datetimeFrom,
    datetimeEnd,
    spinner,
    measurementRequested,
    measurementListFromSelectedChannel
  } = storeToRefs(databaseStore);

  const {
    fetchDownloadChannelsList,
  } = databaseStore

  const state = reactive({
        errorSelectionModal: null
    })

  const deleteChannelList = (event) => {
    try {
      channelSelected.value.length = 0;
      measurementListFromSelectedChannel.value.length = [];
    } catch (error) {
      event.preventDefault();
    }
  }
  const downloadChannelsList = async(event) => {
    const currentTimestamp = Date.now();
    spinner.value = true
    const response = await fetchDownloadChannelsList(
    {
      measurement:measurementSelected.value,
      fileName:currentTimestamp,
      interval: interval.value
    });

    spinner.value = false
    if (response.error == true) {
      errorMessage.value = response.message
      state.errorSelectionModal.show()
    } 
  }

  onMounted(async () => {
    await databaseStore.fetchDatabases();
    state.errorSelectionModal = new bootstrap.Modal('#errorSelectionModal', {})
  })

  const closeErrorModal = () => {
    state.errorSelectionModal.hide()
  }
</script>
