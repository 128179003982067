<template>
  <div class="container">
    <div class="card h-100">
      <div class="card-header">
      <b>Measurements</b>
      </div>
      <div class="card-body">
        <p class="card-text">
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Measurement: </label>
            <div class="col-sm-8">
              <select class="form-select" v-model="measurementSelected"
                aria-label="Default select example"
                :disabled="databaseStore.measurementAggregator.length === 0"
                @change="getFilesFromMeasurements($event)">
                <option v-for="measurement in databaseStore.measurementAggregator"
                  :key="measurement.id">
                  {{ measurement.measurement }}
                </option>
              </select>
            </div>
          </div>
        </p>
        <p class="card-text">
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Files: </label>
            <div class="col-sm-8">
              <select class="form-select" v-model="fileSelected"
                :disabled="databaseStore.measurementAggregator.length === 0"
                aria-label="Default select example" @change="getChannelsFromForm">
                <option v-for="file in filesFromMeasurement"
                  :key="file.id">
                  {{ file }}
                </option>
              </select>
            </div>                
          </div>
        </p>
      </div>
    </div>
  </div>

<!-- Error Modal -->
<div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModal_label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="errorModal_label">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
          </svg>
        </h5>
        <button type="button" class="btn-close" aria-label="Close" @click="closeErrorModal"></button>
      </div>
      <div class="modal-body">
        {{ errorMessage }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" @click="closeErrorModal">Close</button>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
  import { useDatabases } from '@/store/DBStore';
  import { storeToRefs } from 'pinia';
  import { onMounted, reactive, ref } from "vue";

  const databaseStore = useDatabases();
  const {
    measurementSelected,
    fileSelected,
    filesFromMeasurement
  } = storeToRefs(databaseStore);
  const {
    fetchChannelsFromDevice
  } = databaseStore

  const errorMessage = ref('');
  const state = reactive({
      errorModal: null
  })

  onMounted(async () => {
    await databaseStore.fetchDatabases();
    state.errorModal = new bootstrap.Modal('#errorModal', {})
  })

  const getFilesFromMeasurements = (event) => {
    try {
      const _index = Number(event.target.selectedOptions[0].index)
      filesFromMeasurement.value = databaseStore.measurementAggregator[_index].files
      measurementSelected.value = databaseStore.measurementAggregator[_index].measurement
    } catch (error) {
      event.preventDefault();
    }
  }

  const getChannelsFromForm = (event) => {
    try {
      const fileSelected = event.target.value
      fetchChannelsFromDevice(measurementSelected.value, fileSelected)
    } catch (error) {
      event.preventDefault();
    }
  }
  const closeErrorModal = () => {
    state.errorModal.hide()
  }
</script>
