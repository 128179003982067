<template>
<div class="container">
    <div class="loading-state" v-show="spinner">
        <div class="spinner-border"></div>
    </div>
    <div class="row align-items my-4">
        <div class="col">
            <DevicesComponent />
        </div>
    </div>
    <div class="row align-items my-4">
        <div class="col">
            <ChannelsSelectedComponent />
        </div>
    </div>
</div>
</template>

<script setup>
import DevicesComponent from '@/components/DevicesComponent.vue'
import ChannelsSelectedComponent from '@/components/ChannelsSelectedComponent.vue'
import { useDatabases } from '@/store/DBStore'
import { storeToRefs } from 'pinia';

const databaseStore = useDatabases();
const { spinner } = storeToRefs(databaseStore);
</script>
<style>
.loading-state {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>