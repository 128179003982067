import axios from "axios"
import { useUser } from '@/store/UserStore';
import { defineStore } from 'pinia'
import { watch } from "vue";

const todayDate = new Date();

const format = ($this) => {
  const day = $this.getDate().toString().padStart(2, '0');
  const _month = $this.getMonth()+1;
  const month = _month.toString().padStart(2, '0');
  const year = $this.getFullYear();
  const hours = $this.getHours().toString().padStart(2, '0');
  const minutes = $this.getMinutes().toString().padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`
}

export const useDatabases = defineStore('databases', {

  state: () => ({
    datetimeEnd: null,
    datetimeFrom: null,
    databases: [],
    devices: [],
    channels: [],
    databaseSelected: null,
    deviceSelected: null,
    fileSelected: null,
    loading: false,
    spinner: false,
    channelSelected: [],
    measurementRequested: null,
    measurementSelected: null,
    measurementAggregator: [],
    measurementListFromSelectedChannel: [],
    filesFromMeasurement: [],
    channelsFromDevice: [],
    chartOptions: {
      chart: {
        type: 'line',
        zooming: {
          type: 'x'
        }
      },
      title: {
        text: ""
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: ""
        }
      },
      lang: {
        locale: 'en'
        },
      series: []
    }
  }),
  getters: {
    getSelectedDatabase: (state) => state.databaseSelected,
    getDatabases: (state) => state.databases,
    getChannelsForList: (state) => {
      return state.channels.flatMap(item => item.channels.map(channel => channel));
    }
  },
  actions: {
    async fetchDatabases() {
      const useUserStore = useUser();
      this.loading = true
      try {
        const response = await axios.get(
          "/api/v1/sincere/databases", {
            headers: {
            'Authorization': `Bearer ${useUserStore.token}`
          }}
        )
        this.databases = response.data.data
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false
      }
    },
    async fetchDevices() {
      const useUserStore = useUser();
      this.loading = true
      try {
        const response = await axios.get(
          "/api/v1/sincere/" + this.databaseSelected + "/devices/?limit=100&page=0&pagination=false", {
            headers: {
            'Authorization': `Bearer ${useUserStore.token}`
          }}
        )
        this.devices = response.data.data
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false
      }
    },
    async fetchMeasurementAgregator() {
      try {
        const useUserStore = useUser();
        const response = await axios.get(
          "/api/v1/sincere/" + this.databaseSelected + "/measurements_aggregator", {
            headers: {
            'Authorization': `Bearer ${useUserStore.token}`
          }}
        )
        //Initialize variables
        this.measurementAggregator = response.data.data
        this.channelSelected = []
        this.devices = []
        this.channels = []
        this.channelsFromDevice = []
        this.filesFromMeasurement = []
        this.deviceSelected = null
        this.fileSelected = null
        this.measurementSelected = null
      } catch (error) {
        alert(error);
      }
    },
    async fetchChannelsFromDevice(measurement, file) {
      const useUserStore = useUser();
      this.loading = true
      try {
        const response = await axios.get(
          "/api/v1/sincere/" + this.databaseSelected + "/channels_from_file?measurement=" + measurement +  "&file=" + file, {
            headers: {
            'Authorization': `Bearer ${useUserStore.token}`
          }}
        )
        this.channels = response.data.data
        this.measurementSelected= measurement
        this.fileSelected = file
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false
      }
    },
    async fetchDownloadChannelsList(values) {
      const useUserStore = useUser();
      this.loading = true
      let message = null

      let channelsList = this.channelSelected.map(channelObj => channelObj.channel_id);
      
      const datetimeFrom = format(new Date(this.datetimeFrom))
      const datetimeEnd = format(new Date(this.datetimeEnd))

      if ((datetimeEnd < datetimeFrom) || (datetimeEnd == datetimeFrom)) {
        message = "End date must be greater than start date"
        return { error: true , message: message }
      }
      if ((values.interval == null) || (values.interval == 0) || (values.interval == "")) {
        message = "Please select an interval"
        return { error: true , message: message }
      }
      if ((this.measurementRequested == null) || (this.measurementRequested == "")) {
        message = "Please select a measurement"
        return { error: true , message: message }
      }
      await axios.put(
        "/api/v1/sincere/" + this.databaseSelected + "/download_channel_values?measurement=" +
        this.measurementRequested + "&datetime_from=" + datetimeFrom +
        "&datetime_end=" + datetimeEnd + "&interval=" +
        values.interval + "&file_name=" + values.fileName, channelsList, {
          responseType: 'blob',
          headers: {
          'Authorization': `Bearer ${useUserStore.token}`
        }}
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', values.fileName + '.csv');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        message = error
      }).finally(() => {
        this.loading = false
      })

      if (message != null) {
        return { error: true , message: message }
      } else {
        return { error: false }
      }
    },

    async fetchGetChannels(value) {
      const useUserStore = useUser();
      this.loading = true
      try {
        const response = await axios.get(
          "/api/v1/sincere/" + this.databaseSelected + "/device/" + value, {
            headers: {
            'Authorization': `Bearer ${useUserStore.token}`
          }}
        )
        this.channelsFromDevice = response.data     
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false
      }
    },
    async fetchGetChannelValues(channel, measurement = null) {
      const useUserStore = useUser();
      this.loading = true
      this.chartOptions.series = []
      
      let measurementAPI = this.measurementSelected
      let message = null

      if (measurement != null) {
        measurementAPI = measurement
      }
      await axios.put(
        "/api/v1/sincere/" + this.databaseSelected + "/channel_values?measurement=" + measurementAPI,
        [channel.channel_id], {
          headers: {
            'Authorization': `Bearer ${useUserStore.token}`
          }}
      ).then((response) => {
        this.chartOptions.series = response.data.data
        this.chartOptions.series[0].name = channel.item
        this.chartOptions.yAxis.title.text = channel.unit
      }).catch((error) => {
        message = error
      }).finally(() => {
        this.loading = false
      })

      if (this.chartOptions.series.length == 0) {
        return { error: true , message: message }
      } else {
        return { error: false, message: message }
      }
    }
  },
  persist: true
})