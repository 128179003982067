<template>
<div class="container">
    <div class="loading-state" v-show="spinner">
        <div class="spinner-border"></div>
    </div>
    <div class="row align-items my-4">
        <div class="col">
            <DatabaseComponent />
        </div>
        <div class="col">
            <MeasurementAggregatorComponent />
        </div>
    </div>
    <div class="row align-items my-4">
        <div class="col">
            <ChannelsListomponent />
        </div>
    </div>
    <div class="row align-items my-4">
        <div class="col">
            <ChannelsSelectedComponent />
        </div>
    </div>
</div>
</template>

<script setup>
// @ is an alias to /src
import DatabaseComponent from '@/components/DatabaseComponent.vue'
import MeasurementAggregatorComponent from '@/components/MeasurementAgregatorComponent.vue'
// import MeasurementsComponent from '@/components/MeasurementsComponent.vue'
import ChannelsListomponent from '@/components/ChannelsListComponent.vue'
import ChannelsSelectedComponent from '@/components/ChannelsSelectedComponent.vue'

import { useDatabases } from '@/store/DBStore';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
const databaseStore = useDatabases();
const { databaseSelected } = storeToRefs(databaseStore);

const { spinner } = storeToRefs(databaseStore);

components: {
    DatabaseComponent
    // MeasurementsComponent
}

onMounted(async () => {
    spinner.value = false;
})
</script>
<style>
.loading-state {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>